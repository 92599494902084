// External
import React, { useEffect, useMemo, useState } from 'react';
import { Button, ButtonGroup, Navbar, Container } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { FaCalendarAlt, FaDownload } from 'react-icons/fa';
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';

// Internal
import { ContactParagraph, NavBar, TooltipCustom } from 'general_components';
import { createRadioToogleButtonsFromArrOfDict } from './../Dashboard/components/GraphsPage/utils/utils';
import { axiosCustom } from 'general_utils/axios';
import UserInformationStorage from 'general_utils/userinformationstorage';
import DateManager from 'general_utils/datemanager';
import { general_config } from 'general_utils/general_configuration';
import { BackToGraphsButton, BackToCatchmentSelectionButton } from 'general_components/CustomButtons';

// Style
import styles from './RasterDownloads.module.scss';
import './RasterDownloads.scss';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

let columnDefs = [];

const sortByDateDescending = (arr) => {
    arr.sort((a, b) => new Date(b.date) - new Date(a.date));
    return arr;
}

const catchmentGeoJson = JSON.parse(UserInformationStorage.get_user_information('catchmentGeoJson'));
const datemanager = new DateManager();

function RasterDownloads() {

    const catchmentDisplayName = (catchmentGeoJson.length > 0 ? catchmentGeoJson[0].properties.display_name : '');
    const catchmentName = (catchmentGeoJson.length > 0 ? catchmentGeoJson[0].properties.name : '');
    const catchmentCountry = (catchmentGeoJson.length > 0 ? catchmentGeoJson[0].properties.country : '');
    const catchmentArea = (catchmentGeoJson.length > 0 ? (catchmentGeoJson[0].properties.area > 0 ? '. Area: ' + catchmentGeoJson[0].properties.area + ' km²' : ''):'');

    const LOCAL_DATE_FORMAT = DateManager.getLocaleDateString();

    const onClickAction = (row) => {
        try {
            axiosCustom.get(`catchments/${catchmentName}/${productName?.toLowerCase()}-rasters/${row.file_name}`, { responseType: 'arraybuffer' }).then((response) => {

                var binaryData = [];
                const fileName = `${catchmentName}_${productName?.toLowerCase()}_${row.file_name}`;
                binaryData.push(response?.data);
                const href = URL.createObjectURL(new Blob(binaryData, { type: "image/tiff" }));
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            })
        } catch (error) {
            console.error(error);
        }
    }

    const addButtonDownload = (data) => {
        return (
            <TooltipCustom
                placement={'bottom'}
                text={"Download the file"}>
                <Button size="sm" variant='wegaw-darkblue' onClick={() => onClickAction(data)}>
                    <FaDownload />
                </Button>
            </TooltipCustom>
        );
    };

    columnDefs = [
        {
            filter: 'agDateColumnFilter',
            headerName: 'Date',
            field: 'date',
            sortable: true,
            cellRenderer: p => DateManager.dateToLocalDateString(p.data.date),
        },
        {
            headerName: 'URL',
            field: 'url',
            cellRenderer: p => addButtonDownload(p.data),
        },
    ];

    const defaultColDef = useMemo(() => {
        return {
            floatingFilter: true,
            flex: 1,
            resizable: false,
        }
    }, []);

    const [radioProductValue, setRadioProductValue] = useState(general_config.default_product);
    const [productName, setProductName] = useState(general_config.default_product);
    const [rowData, setRowData] = useState([])

    const [selectedTo, setSelectedTo] = useState(datemanager.ISOStringToDate(new Date().toISOString()));
    const [selectedFrom, setSelectedFrom] = useState(datemanager.ISOStringToDate(datemanager.unixTimestampToDatetime(datemanager.substractDaysToUnixTimestamp(new Date(), 30)).toISOString()));
    const [showFrom, setShowFrom] = useState(datemanager.unixTimestampToDatetime(datemanager.substractDaysToUnixTimestamp(new Date(), 30)));
    const [showTo, setShowTo] = useState(new Date());
    const [listOfProducts, setListOfProducts] = useState([general_config.default_product]);

    const handleChange = (parameter) => {
        setProductName(parameter.product);
    };

    useEffect(() => {
        axiosCustom.get(`catchments/${catchmentName}/${productName?.toLowerCase()}-rasters?to=${selectedTo}&from=${selectedFrom}`).then(res => {
            if (res?.data) setRowData(sortByDateDescending(res?.data.snowmaps))
        })
    }, [selectedTo, selectedFrom, productName]);

    useEffect(() => {
        axiosCustom.get(`catchmentProducts/${catchmentName}`)
            .then(res => {
                setListOfProducts(res.data[0].available_products);
            })
    }, []);

    const productsDict = listOfProducts.filter(item => item.toLowerCase() !== 'pp' && item.toLowerCase() !== 'swevolume').map(item => {
        return {
            tag: item.toUpperCase(),
            product: item
        };
    });

    return (
        <>
            <NavBar>
                <Navbar>
                    <strong>{catchmentDisplayName}{`, ${catchmentCountry}`}{` ${catchmentArea}`}</strong>
                </Navbar>
                <BackToCatchmentSelectionButton/>
                <BackToGraphsButton/>
            </NavBar>
            <Container className={`mt-3 mb-3 ${styles.downloadContainer}`}>
                <Container className={'tableContainer'}>
                    <Container>
                        <ButtonGroup className='btn-toolbar mb-2'>
                            {
                                createRadioToogleButtonsFromArrOfDict(
                                    productsDict,
                                    'productFilter',
                                    radioProductValue,
                                    setRadioProductValue,
                                    handleChange,
                                    'graph'
                                )
                            }
                        </ButtonGroup>
                    </Container>
                    <div className={`pb-2 pt-2 centerElement`}>
                        <div className={`pb-2 pt-2 ${styles.dateContainer}`}>
                            <div className={styles.toCalendar}>
                                <p>From <FaCalendarAlt /></p>
                                <DatePicker
                                    dateFormat={LOCAL_DATE_FORMAT}
                                    showYearDropdown
                                    selected={showFrom}
                                    onChange={(fromStringDatetime) => {
                                        let from = datemanager.ISOStringToDate(datemanager.unixTimestampToDatetime(fromStringDatetime).toISOString())
                                        setShowFrom(fromStringDatetime)
                                        setSelectedFrom(from)
                                    }}
                                />
                            </div>
                        </div>
                        <div className={`pb-2 pt-2 ${styles.dateContainer}`}>
                            <div className={styles.toCalendar}>
                                <p>To <FaCalendarAlt /></p>
                                <DatePicker
                                    dateFormat={LOCAL_DATE_FORMAT}
                                    showYearDropdown
                                    selected={showTo}
                                    onChange={(toStringDatetime) => {
                                        let to = datemanager.ISOStringToDate(datemanager.unixTimestampToDatetime(toStringDatetime).toISOString())
                                        setShowTo(toStringDatetime)
                                        setSelectedTo(to)
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <Container className={`text-center mt-3 mb-3`}>
                        <div className="ag-theme-quartz" id="rasterDownloadsContainer">
                            <AgGridReact
                                rowData={rowData}
                                columnDefs={columnDefs}
                                defaultColDef={defaultColDef}
                                rowSelection="multiple"
                                suppressRowClickSelection={true}
                                pagination={true}
                                paginationPageSize={10}
                                paginationPageSizeSelector={[10, 25, 50]}
                                domLayout={'autoHeight'}
                            />
                        </div>
                    </Container>
                </Container>
            </Container>
            <ContactParagraph />
        </>
    );
};

export default RasterDownloads;